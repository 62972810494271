import React from "react";
import { Link } from "react-router-dom";

export default function Ticker({ items }) {

	const ads = [
		<>
			How are you <Link to="/feels">feeling today?</Link>
		</>,
		<>
			Maybe a <Link to="/applauds">small compliment</Link> from one colleague to
			another?
		</>,
		<>
			What’s new in Drama Queen? <Link to="/weekly">Check the weekly.</Link>
		</>,
	];


	const finalItems =
		items && items.length > 0
			? items
			: Array(4).fill(
					<>
						Welcome to the <em>Royal Backstage!</em>
					</>
			  );
		
	for (let i = 0; i < ads.length; i++) {
		finalItems.splice((i + 1) * 2 - 1, 0, ads[i]);
	}
	
	// finalItems.push(' Now with a <em>working</em> search functionality! 🙈 ');
				// finalItems.push('Now with 100% more podlings');
				// finalItems.push(
				// 	"Now with 100% more <a href='/feels'><i>feeelings</i></a>"
				// );
			// : Array(5).fill(` This is a ticker, isn't it <em>great?</em>`);

	return (
		<div className="c-marquee">
			<ul className="marquee__content">
				{finalItems.map((a, i) => (
					<li key={i} >{a}</li>
				))}
			</ul>
			<ul className="marquee__content">
				{finalItems.map((a, i) => (
					<li key={i} >{a}</li>
				))}
			</ul>
			{/* <ul className="marquee__content">
				{finalItems.map((a, i) => (
					<li key={i} dangerouslySetInnerHTML={{__html:a}} />
				))}
			</ul>
			<ul className="marquee__content">
				{finalItems.map((a, i) => (
					<li key={i} dangerouslySetInnerHTML={{__html:a}} />
				))}
			</ul> */}
		</div>
	);
}